import React from 'react';
import AndroidTopBar from '../../AppAssets/Icons/svg/AndroidTopBar';
import IosTopBar from '../../AppAssets/Icons/svg/IosTopBar';
import AndroidBackArrow from '../../AppAssets/Icons/svg/AndroidBackArrow';
import downArrow from '../../AppAssets/Icons/down-arrow.png';
import fakePW from '../../AppAssets/Icons/android-eye.png';

const CreateAccount = ({ device }) => (
    <div>
        <div className="view view-createAccount createAccount">
            {/* <!-- header   --> */}
            <div className="createAccount-header">
                {device !== 'Android' && <IosTopBar />}
                {device === 'Android' && <AndroidTopBar />}
                <div>
                    <AndroidBackArrow />
                    <h1>Create Account</h1>
                    <div />
                </div>
            </div>

            <div className="createAccount-formWrap">
                <div className="fakeInput">First Name</div>
                <div className="fakeInput">Last Name</div>
                <div className="fakeInput">Email</div>
                <div className="fakeInput icon">
                    Password (8+ Characters)
                    <img src={fakePW} alt="" />
                </div>
                <div className="fakeInput">Phone Number (optional)</div>
                <div className="fakeInput">Pronouns (optional)</div>
                <div className="fakeInput icon">
                    Your Preferred Studio
                    <img src={downArrow} alt="" />
                </div>
                <div className="toggleRow">
                    <p>I would like to sign up to recieve newsletters and promotions?</p>
                    <div className="toggle">
                        <div className="toggle-circle" />
                        <div className="toggle-track" />
                    </div>
                </div>
                <div className="toggleRow">
                    <p>
                        I agree to the
                        {' '}
                        <span>Terms of Service & Waiver.</span>
                    </p>
                    <div className="toggle">
                        <div className="toggle-circle" />
                        <div className="toggle-track" />
                    </div>
                </div>
                <button type="button" className="btn-secondary">Sign Up</button>
            </div>
        </div>
    </div>
);

export default CreateAccount;
